import React from "react";
import { Link } from "react-router-dom";
import "./teacher-card.css";
import logo from "../../static/img/schooltube_logo_new.png";
import youtubeIcon from "../../static/img/youtube.png";
import backgroundImage from "../../static/img/teacher_background_big.png"; // Import your background image

<style>
  
</style>
function TeacherCard(props) {
  const { TeacherName, TeacherMajor, teacherImg, youtubeLink ,teacherIndex} = props;

  return (
    <>
    <div className="teacher-card-1" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="absolute top-2 left-2">
          <img
            src={logo}
            alt="logo"
            className="w-[70px] h-[54px] object-contain"
          />
        </div>
        <div className="teachers-cards-container">
        <Link 
        key={teacherIndex}
         to={{
                      pathname: "/teachers-details",
                      state: {
                        TeacherName: TeacherName,
                        TeacherDescription: TeacherMajor,
                        youtubeLink: youtubeLink,
                        teacherImg: teacherImg,
                      },
                    }}>
          <img src={teacherImg} alt="teacher" className="teacher-card-image" />
          </Link>
          <a
            href={youtubeLink}
            target="_blank"
            className="teacher-card-overlay-link"
          >
          
              <img
                src={youtubeIcon}
                alt="YouTube Icon"
                className="teacher-card-overlay"
              />
       
          </a>
        </div>
        <Link 
        key={teacherIndex}
         className="w-[100%]"
        to={{
                      pathname: "/teachers-details",
                      state: {
                        TeacherName: TeacherName,
                        TeacherDescription: TeacherMajor,
                        youtubeLink: youtubeLink,
                        teacherImg: teacherImg,
                      },
                     
                    }}>
        <div className="teacher-card-text-content teacher-card-text">
          <p className="font-bold">{TeacherName}</p>
          <p title={TeacherMajor} className="text-sm  truncate-text">{TeacherMajor}</p>
        </div>
        </Link>
      </div>
    </>
  );
}

export default TeacherCard;

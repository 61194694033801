import React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import Button from "react-bootstrap/esm/Button";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import SmallVideosDropdownForMyLearningCla from "../SmallVideosDropdownForMyLearningCla";
import "./IntroductionVideoMyLearningMoreSect.css";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import FaqElement from "./FaqElement";
import GetCode from "./GetCode";
import CircularProgress from "@mui/material/CircularProgress";
import LikeHeart from "./LikeHeart";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setMaterialFetched,
  setMaterialDataGlobal,
} from "../../store/DataFetched";
import Swal from "sweetalert2";

/**for responsivity  */
import { useMediaQuery } from "react-responsive";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { URL } from "../../static/data/config";
import backwardIcon from "../../static/img/backward-10-seconds.svg";
import forwardIcon from "../../static/img/forward-10-seconds.svg";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

/*Functions for the tabs section*/
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

/**function to convert the seconds to H:M:S */
function formatTime(time) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  return (
    (hours > 0 ? hours + ":" : "") +
    (minutes < 10 ? "0" : "") +
    minutes +
    ":" +
    (seconds < 10 ? "0" : "") +
    seconds
  );
}

function IntroductionVideoMyLearningMoreSect(props) {
  const token =
    localStorage.getItem("login-token") ??
    sessionStorage.getItem("login-token");
  /**for progress */

  const [materials_seen_videos, setMaterialsSeenVideos] = useState(0);
  const [materials_count_videos, setMaterialsCountVideos] = useState(0);

  /*Loading function starts here*/
  const [isLoading, setIsLoading] = useState(true);
  /*Loading function ends here */

  /*fetching the data for all the grades */
  // const [grade_id, fetchgradeid] = useState(1);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const grade_id = params.get("id");
  const grade_mid = params.get("mID");

  const [singleGradeData, setSingleGrade] = useState([]);
  const [material_id, setMaterilaId] = useState();

  const [expiryDate, setExpiryDate] = useState("");

  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const isMaterialFetched = useSelector(
    (state) => state.dataFetched.isMaterialFetched
  );
  const materialDataGlobal = useSelector(
    (state) => state.dataFetched.materialData
  );

  async function getData() {
    let formData = new FormData();
    formData.append("token", token);
    formData.append("grade_id", grade_id);
    formData.append("faq_limit", 10);
    const url1 = `${URL}material/materials`;
    axios
      .post(url1, formData)
      .then((res) => {
        setSingleGrade(res.data);
        setMaterilaId(res.data?.materials[0]?.id);
        //new
        if (res.data.logged_out == true) {
          localStorage.setItem("login-token", null);
          sessionStorage.setItem("login-token", null);
        }
        if (res.data.materials[0].access_expiry_date) {
          const dateObject = new Date(res.data.materials[0].access_expiry_date);
          const year = dateObject.getFullYear();
          const month = String(dateObject.getMonth() + 1).padStart(2, "0");
          const day = String(dateObject.getDate()).padStart(2, "0");
          const expiry_date = `${year}-${month}-${day}`;
          setExpiryDate(expiry_date);
        }
        //
        setData(res.data.data);
        dispatch(setMaterialDataGlobal(res.data));
        setIsLoading(false);
        dispatch(setMaterialFetched());
        setIsLoading(false);
        setPreviewVideoUrl(res.data.materials[0].preview_video.url);
        setPreviewVideoName(res.data.materials[0]?.preview_video.name);
        setMaterialsSeenVideos(res.data.materials[0]?.seen_videos_count);
        setMaterialsCountVideos(res.data.materials[0]?.videos_count);
        setMaterialImgSrc(res.data.materials[0].image);
        setMaterialDescription(res.data.materials[0].description);
        if (!res.data?.materials[0].material_access) {
          setShowRequestBtn(true);
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (!isMaterialFetched) {
      getData();
    } else {
      setSingleGrade(materialDataGlobal);
      setIsLoading(false);
    }
  }, [data]);

  /*Ending fetching data here */

  /**to get the more description of each materials */
  const [materialDescription, setMaterialDescription] = useState();

  /*getting the categ items */

  const [gradeCards, setGradeCards] = useState([]);
  const homeDataGlobal = useSelector((state) => state.dataFetched.homeData);

  function getHomeData() {
    let formData = new FormData();
    formData.append("token", token);
    formData.append("is_web", true);
    const url1 = `${URL}side/home-data`;
    fetch(url1, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setGradeCards(res);
      })
      .catch((err) => console.log(err));
  }

  //console.log(gradeCards);

  useEffect(() => {
    if (homeDataGlobal) {
      setGradeCards(homeDataGlobal);
    } else {
      getHomeData();
    }
  }, []);
  /*Ending fetching data here */

  /*getting the categ items */
  const categData = [];
  {
    gradeCards.categories &&
      gradeCards.categories.map((categItem) => {
        categData.push(categItem);
      });
  }

  const gradeTitle = location.state && location.state.gradeTitle;
  const fromPage = location.state && location.state.fromPage;

  const videoRef = useRef(null);

  /*vars for tabs*/
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  /**to add style to the selected material */
  const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(0);

  //get the material id to send it to the accordion component
  const [material_video, setmaterial_video] = useState(0);
  useEffect(() => {
    if (params.get("mID")) {
      const specificId = singleGradeData?.materials?.findIndex(
        (item) => item.id === parseInt(params.get("mID"))
      );
      setmaterial_video(specificId);
    }
  }, [data]);

  /*to take down the img and put the video */
  const [material_img_viedo, setMaterialImgVid] = useState(false);
  const handleMaterialImgChange = (value) => {
    setMaterialImgVid(value);
  };
  /** tot set the src of img of the material  */
  const [materialImgSrc, setMaterialImgSrc] = useState(null);

  /*for the resolution */
  const [currentResolution, setCurrentResolution] = useState(360);
  /**when the user select the resolution*/
  function handleSelectResolution(VideoUrlLink, resolution) {
    const parts = VideoUrlLink.split("/");
    let src = "";
    if (resolution === 360) {
      src = parts.slice(0, -1).join("/") + "/360/" + parts.slice(-1)[0];
    } else {
      src = parts.slice(0, -1).join("/") + "/" + parts.slice(-1)[0];
    }
    // console.log(src);
    return src;
  }

  function videoErrorHandler() {
    if (currentResolution === 360) {
      setCurrentResolution(720);
    }
  }

  /**to keep the progress */
  const handleResolutionChange = (r) => {
    setCurrentResolution(r);
  };

  const handleVideoChange = (info) => {
    setVideoInfo(info);
    setMaterialVideoTitle(true);
    setMaterialImgVid(true);
    setVideoProgress({ progress: 0, url: info.url });
  };

  // const scrollToTop = useCallback(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  // useEffect(() => {
  //   scrollToTop();
  // }, [handleVideoChange, scrollToTop]);

  /*to handle the material title when u change the material */
  const [materialVideoTitle, setMaterialVideoTitle] = useState();

  /**for the time */
  const [currentTime, setCurrentTime] = useState();
  const [isEnded, setIsEnded] = useState(false);
  function handleVideoEnded() {
    setShowResolutionButton(false);
    setIsEnded(true);
    setTimeout(() => {
      setIsEnded(false);
    }, 1000);
    const currentTime = videoRef.current.currentTime;
    setCurrentTime(formatTime(currentTime));
  }

  const [showRequestBtn, setShowRequestBtn] = useState(false);

  const [videoProgress, setVideoProgress] = useState({ progress: 0, url: "" });

  const rewindVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10; // Rewind 10 seconds
    }
  };

  const fastForwardVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10; // Fast-forward 10 seconds
    }
  };

  /**handle the play button of the preview material */
  const [previewVideoUrl, setPreviewVideoUrl] = useState();
  const [previewVideoName, setPreviewVideoName] = useState();
  const [videoInfo, setVideoInfo] = useState({
    id: "",
    url: "",
    name: "",
  });

  function updateVideoInfo(newProperties) {
    setVideoInfo((prevProperties) => ({
      ...prevProperties,
      ...newProperties,
    }));
  }
  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  function handlePlayIcon() {
    if (previewVideoUrl) {
      updateVideoInfo({
        url: previewVideoUrl,
        name: previewVideoName,
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "No Preview Video",
        text: "No Preview Video for this Material",
      });
    }
  }


  // if (singleGradeData && singleGradeData.logged_out == true) {
  //   localStorage.setItem("login-token", null);
  //   sessionStorage.setItem("login-token", null);
  // }

  const history = useHistory();

  const handleGoBackClick = () => {
    if (fromPage === "home") {
      history.push("./");
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    const material = singleGradeData?.materials?.find(
      (item) => item.id == material_id
    );

    if (material?.access_expiry_date) {
      const dateObject = new Date(material?.access_expiry_date);
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const day = String(dateObject.getDate()).padStart(2, "0");
      const expiry_date = `${year}-${month}-${day}`;
      setExpiryDate(expiry_date);
    }
  }, [singleGradeData, material_id]);

  // new
  const [materialLoading, setMaterialLoading] = useState(false);
  const [showResolutionButton, setShowResolutionButton] = useState(false);

  const playVideoHandler = () => {
    setShowResolutionButton(true);
  };

  useEffect(() => {
    if (materialLoading) {
      const timeoutId = setTimeout(() => {
        setMaterialLoading(false);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [materialLoading]);

  if (isLoading) {
    /*shimmer effects */

    return (
      <>
        <div className="shimmer-container">
          <div className="shimmer-video-section">
            <div className="shimmer-video"></div>
            <div className="shimmer-video-title"></div>
            <div className="shimmer-materials-container">
              <div className="shimmer-material-item"></div>
              <div className="shimmer-material-item"></div>
              <div className="shimmer-material-item"></div>
              <div className="shimmer-material-item"></div>
            </div>
          </div>
          <div className="shimmer-accordion">
            <div className="shimmer-accordion-item"></div>
            <div className="shimmer-accordion-item"></div>
            <div className="shimmer-accordion-item"></div>
            <div className="shimmer-accordion-item"></div>
            <div className="shimmer-accordion-item"></div>
          </div>
        </div>
      </>
    );
  }

  const timeUpdateHandler = (e, url) => {
    const videoElement = e.target;
    const progress = videoElement.currentTime / videoElement.duration;
    setVideoProgress({ progress, url });
  };

  return (
    <div className="container-center-horizontal">
      {gradeCards &&
        gradeCards.grades?.map((gradeItem) => {
          if (gradeItem.id === grade_id) {
            return (
              <Helmet>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
                <title>{gradeItem.title} </title>
                <meta
                  name="description"
                  content={`Schooltube offers a user-friendly platform that allows ${gradeItem.title} students to access high-quality educational resources quickly and efficiently.`}
                />
              </Helmet>
            );
          } else {
            <Helmet>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <title>Grade</title>
              <meta
                name="description"
                content={`Schooltube offers a user-friendly platform that allows all grades students to access high-quality educational resources quickly and efficiently. Our curated library of materials covers a wide range of subjects and topics, providing students with accurate and reliable information. With Schooltube, learning and engaging with al grades educational materials is fast and easy.`}
              />
            </Helmet>;
          }
        })}
      {isMobile ? null : (
        <div className="square">
          <div className="square-title">Progress</div>
          <div className="intro-progress-container">
            <CircularProgressWithLabel
              value={
                parseInt(materials_count_videos) === 0
                  ? 0
                  : (parseInt(materials_seen_videos) /
                      parseInt(materials_count_videos)) *
                    100
              }
            />
          </div>
        </div>
      )}
      {isMobile ? (
        <div onClick={handleGoBackClick} className="z-[100] ">
          <img
            className=" cursor-pointer fixed left-0 w-12 !text-white"
            src={"./img/Arrow-Left.svg"}
          />
        </div>
      ) : null}
      <div className="introduction-video-my-learning-more-section screen ">
        <div
          className="introduction-video-my-learning-more-container"
          style={{ width: "100%" }}
        >
          <div>
            <div className="flex-row-15">
              <div className="flex-col-4">
                <div className="!min-h-full w-full relative">
                  {singleGradeData &&
                    (videoInfo?.url && videoInfo?.url?.length > 0 ? (
                      <>
                        <div className="video-container">
                          <video
                            className="video-element"
                            ref={videoRef}
                            autoPlay={true}
                            src={handleSelectResolution(
                              videoInfo.url,
                              currentResolution
                            )}
                            controls
                            controlsList="nodownload"
                            onEnded={handleVideoEnded}
                            onPlay={playVideoHandler}
                            onError={videoErrorHandler}
                            onTimeUpdate={(e) =>
                              timeUpdateHandler(e, videoInfo.url)
                            }
                          ></video>
                       
                          {!isMobile && (
  <div className="controls">
    <button onClick={rewindVideo} className="control-btn rewind-btn">
      <img src={backwardIcon} className="w-[30px] h-[30px]" alt="Rewind" />
    </button>
    <button onClick={fastForwardVideo} className="control-btn fast-forward-btn">
      <img src={forwardIcon} className="w-[30px] h-[30px]" alt="Fast Forward" />
    </button>
  </div>
)}
                        
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="material-image-placeholder "
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            transition: "background-image .5s",
                            minHeight: "100%",
                          }}
                        >
                          {/* new min-h */}
                          {materialLoading ? (
                            <div className="shimmer-video-section">
                              <div className="shimmer-video w-full max-h-[500px] sm:h-[100%] !h-[500px]"></div>
                              <div className="shimmer-video-title"></div>
                            </div>
                          ) : (
                            <>
                              <img
                                className="video-image-thumbnail w-full !min-h-[100px] sm:!min-h-[300px] max-h-[500px] sm:h-[100%] h-[250px] bg-white"
                                src={
                                  materialImgSrc
                                    ? materialImgSrc
                                    : singleGradeData.materials[0] &&
                                      singleGradeData.materials[0].image
                                    ? singleGradeData.materials[0].image
                                    : "./img/grade-image@2x.png"
                                }
                                alt=""
                                //onLoad={handleImageLoad}
                              />
                              <button
                                className="btns absolute top-1/2 left-1/2 transform translate-x-[-50%] translate-y-[-50%]"
                                onClick={handlePlayIcon}
                              >
                                <span className="play"></span>
                                <span className="small-box"></span>
                              </button>
                            </>
                          )}
                        </div>
                      </>
                    ))}
                </div>

                <div
                  className="grade-title-container"
                  style={{ position: "relative " }}
                >
                  <div className="!flex !flex-col !items-start !justify-start">
                    {expiryDate && (
                      <span className="text-red-600 font-semibold">
                        Expiry Date: {expiryDate}
                      </span>
                    )}
                    <h2 className="intro-grade-title">
                      {videoInfo.name?.length > 0 ? videoInfo.name : gradeTitle}
                    </h2>
                  </div>
                  <div className="!flex !flex-col !items-center !justify-between">
                    <div className="!flex !items-center !justify-center">
                      <LikeHeart
                        url={URL}
                        video_id={videoInfo.id}
                        is_liked={videoInfo.is_liked}
                      />
                      {showResolutionButton && (
                        <>
                          <div
                            className={`!p-1 !cursor-pointer !rounded-sm !w-50 !h-50 !italic !text-white ${
                              currentResolution === 360
                                ? "!bg-gray-400"
                                : "!bg-red-500"
                            } !font-bold !text-xl`}
                            onClick={() => handleResolutionChange(720)}
                          >
                            HD
                          </div>
                          <div
                            className={`!ml-2 !p-1 !cursor-pointer !rounded-sm !w-50 !h-50 !italic !text-white ${
                              currentResolution === 720
                                ? "!bg-gray-400"
                                : "!bg-red-500"
                            } !font-bold !text-xl`}
                            onClick={() => handleResolutionChange(360)}
                          >
                            360
                          </div>
                        </>
                      )}
                    </div>
                    {showRequestBtn ? (
                      <div className="!flex !items-center !justify-center !mt-7">
                        <GetCode url={URL} grade_id={grade_id} />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="flex-col-5">
                  <Box sx={isMobile ? { width: "100%" } : { width: "80%" }}>
                    {isMobile && (
                      <div
                        className="sticky-accordion-container mt-[5px] !w-full  "
                        style={{ marginBottom: "20px" }}
                      >
                        <div className="materials-1">
                          <Swiper
                            className=" overflow-x-auto"
                            style={{ width: "100%" }}
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={20}
                            slidesPerView={4}
                            breakpoints={{
                              300: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                                slidesPerGroup: 1,
                              },
                              1000: {
                                slidesPerView: 4,
                                spaceBetween: 10,
                                slidesPerGroup: 2,
                              },
                            }}
                          >
                            {singleGradeData?.materials?.map(
                              (materialItem, index) => {
                                function handleMaterialBtn() {
                                  if (material_id === materialItem.id) {
                                    return;
                                  }
                                  setMaterialLoading(true);
                                  setSelectedMaterialIndex(index);
                                  setMaterilaId(materialItem.id);
                                  setmaterial_video(index);
                                  setMaterialImgVid(true);
                                  setMaterialImgSrc(materialItem?.image);
                                  setMaterialVideoTitle(false);
                                  setMaterialsSeenVideos(
                                    materialItem?.seen_videos_count
                                  );
                                  setMaterialsCountVideos(
                                    materialItem?.videos_count
                                  );
                                  setMaterialDescription(
                                    materialItem?.description
                                  );
                                  setPreviewVideoUrl(
                                    materialItem?.preview_video?.url
                                  );
                                  setPreviewVideoName(
                                    materialItem?.preview_video?.name
                                  );
                                  if (materialItem?.material_access) {
                                    setShowRequestBtn(false);
                                  } else {
                                    setShowRequestBtn(true);
                                  }
                                }
                                if (materialItem?.material_access) {
                                  return (
                                    <SwiperSlide
                                      className=" !w-auto"
                                      style={{
                                        width: "fit-content !important",
                                      }}
                                    >
                                      <div
                                        className={`materials-btn materials-active-btn  ${
                                          material_id == materialItem?.id ||
                                          (index === 0 &&
                                            !selectedMaterialIndex &&
                                            !material_id)
                                            ? "material-selected"
                                            : ""
                                        }`}
                                        key={index}
                                      >
                                        <Button onClick={handleMaterialBtn}>
                                          {materialItem.title}
                                        </Button>
                                      </div>
                                    </SwiperSlide>
                                  );
                                } else {
                                  return (
                                    <SwiperSlide
                                      className=" !w-auto"
                                      style={{
                                        width: "fit-content !important",
                                      }}
                                    >
                                      <div
                                        className={`materials-btn materials-non-active-btn ${
                                          material_id == materialItem?.id ||
                                          (index === 0 &&
                                            !selectedMaterialIndex &&
                                            !material_id) // new
                                            ? "material-selected"
                                            : ""
                                        }`}
                                        key={index}
                                      >
                                        <Button
                                          className=" text-[14px]"
                                          onClick={handleMaterialBtn}
                                          style={
                                            selectedMaterialIndex == index
                                              ? { color: "white" }
                                              : null
                                          }
                                        >
                                          {materialItem.title}
                                        </Button>
                                      </div>
                                    </SwiperSlide>
                                  );
                                }
                              }
                            )}
                          </Swiper>
                        </div>
                        <SmallVideosDropdownForMyLearningCla
                          // drop_map={singleGradeData}
                          // material_id={material_id}
                          // handleVideoChange={handleVideoChange}
                          // categoryTabs={categData}
                          // onMaterialImgChange={handleMaterialImgChange}
                          // url={URL}
                          // videoEndedId={videoInfo.id}
                          // isVideoEnded={isEnded}
                          // time={currentTime}
                          // //image_url={image_url}
                          // video_id={videoInfo.id}
                          // selectedVideo={materialVideoTitle}
                          // material_video={material_video}
                          drop_map={singleGradeData}
                          material_id={material_id}
                          handleVideoChange={handleVideoChange}
                          categoryTabs={categData}
                          onMaterialImgChange={handleMaterialImgChange}
                          url={URL}
                          videoEndedId={videoInfo.id}
                          isVideoEnded={isEnded}
                          time={currentTime}
                          //image_url={image_url}
                          video_id={videoInfo.id}
                          selectedVideo={materialVideoTitle}
                          material_video={material_video}
                          // new
                          videoProgress={videoProgress}
                        />
                      </div>
                    )}
                    {!isMobile && (
                      <>
                        <TabPanel value={value} index={isMobile ? 1 : 0}>
                          <p>
                            {
                              singleGradeData?.materials[material_video]
                                ?.description
                            }
                          </p>
                        </TabPanel>
                        <TabPanel value={value} index={isMobile ? 2 : 1}>
                          <FaqElement
                            url={URL}
                            //image_url={image_url}
                            video_id={videoInfo.id}
                            selectedVideo={materialVideoTitle}
                          />
                        </TabPanel>
                      </>
                    )}
                  </Box>
                </div>
              </div>

              {!isMobile && (
                <div
                  className="sticky-accordion-container mt-[5px]"
                  style={{ marginBottom: "20px" }}
                >
                  <div className="materials-1">
                    <Swiper
                      className=" overflow-x-auto"
                      style={{ width: "100%" }}
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      spaceBetween={20}
                      slidesPerView={4}
                      breakpoints={{
                        300: {
                          slidesPerView: 3,
                          spaceBetween: 20,
                          slidesPerGroup: 1,
                        },
                        1000: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                          slidesPerGroup: 2,
                        },
                      }}
                    >
                      {singleGradeData?.materials?.map(
                        (materialItem, index) => {
                          function handleMaterialBtn() {
                            if (material_id === materialItem.id) {
                              return;
                            }
                            setMaterialLoading(true);
                            setSelectedMaterialIndex(index);
                            setMaterilaId(materialItem.id);
                            setmaterial_video(index);
                            setMaterialImgVid(true);
                            setMaterialImgSrc(materialItem?.image);
                            setMaterialVideoTitle(false);
                            setMaterialsSeenVideos(
                              materialItem?.seen_videos_count
                            );
                            setMaterialsCountVideos(materialItem?.videos_count);
                            setMaterialDescription(materialItem?.description);
                            setPreviewVideoUrl(
                              materialItem?.preview_video?.url
                            );
                            setPreviewVideoName(
                              materialItem?.preview_video?.name
                            );
                            if (materialItem?.material_access) {
                              setShowRequestBtn(false);
                            } else {
                              setShowRequestBtn(true);
                            }
                          }
                          if (materialItem?.material_access) {
                            return (
                              <SwiperSlide
                                className=" !w-auto"
                                style={{ width: "fit-content !important" }}
                              >
                                <div
                                  className={`materials-btn materials-active-btn  ${
                                    material_id == materialItem?.id ||
                                    (index === 0 &&
                                      !selectedMaterialIndex &&
                                      !material_id)
                                      ? "material-selected"
                                      : ""
                                  }`}
                                  key={index}
                                >
                                  <Button onClick={handleMaterialBtn}>
                                    {materialItem.title}
                                  </Button>
                                </div>
                              </SwiperSlide>
                            );
                          } else {
                            return (
                              <SwiperSlide
                                className=" !w-auto"
                                style={{ width: "fit-content !important" }}
                              >
                                <div
                                  className={`materials-btn materials-non-active-btn ${
                                    material_id == materialItem?.id ||
                                    (index === 0 &&
                                      !selectedMaterialIndex &&
                                      !material_id) // new
                                      ? "material-selected"
                                      : ""
                                  }`}
                                  key={index}
                                >
                                  <Button
                                    className=" text-[14px]"
                                    onClick={handleMaterialBtn}
                                    style={
                                      selectedMaterialIndex == index
                                        ? { color: "white" }
                                        : null
                                    }
                                  >
                                    {materialItem.title}
                                  </Button>
                                </div>
                              </SwiperSlide>
                            );
                          }
                        }
                      )}
                    </Swiper>
                  </div>
                  <SmallVideosDropdownForMyLearningCla
                    drop_map={singleGradeData}
                    material_id={material_id}
                    handleVideoChange={handleVideoChange}
                    categoryTabs={categData}
                    onMaterialImgChange={handleMaterialImgChange}
                    url={URL}
                    videoEndedId={videoInfo.id}
                    isVideoEnded={isEnded}
                    time={currentTime}
                    //image_url={image_url}
                    video_id={videoInfo.id}
                    selectedVideo={materialVideoTitle}
                    material_video={material_video}
                    // new
                    videoProgress={videoProgress}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntroductionVideoMyLearningMoreSect;

import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import "./contact-us.css";
import TeacherTitleLines from "../TitleLines/TeacherTitleLines";
import Navbar from "../Navbar/Navbar";
import UnlockPowerOfStudySection from "../UnlockPowerOfStudySection";
import { TextField } from "@mui/material";
import { Button, Form, Row } from "react-bootstrap";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import Tr from "../translation/Utils";
import Swal from "sweetalert2";
import TextTR from "../HomePage/TextTR";
import GoogleRecaptcha from "../GoogleRecaptcha";
import { useTranslation } from "../translationUtils";
import { setNotificationDrawer } from "../../store/CheckLogin";
import { useDispatch } from "react-redux";
import { URL } from "../../static/data/config";

function ContactUS(props) {
  const { unlockPowerOfStudySectionProps } = props;
  const dispatch = useDispatch();
  const { getTranslatedWord, setLanguage } = useTranslation();

  /*handling submit */
  const [userEmail, setUserEmail] = useState();
  const [userMessage, setUserMessage] = useState();
  const [recaptchaResponse, setRecaptchaResponse] = useState();
  const [recaptchaKey, setRecaptchaKey] = useState(0);

  async function handleContactUsSubmit(event) {
    event.preventDefault();

    let formData = new FormData();
    formData.append(
      "token",
      localStorage.getItem("login-token") ??
        sessionStorage.getItem("login-token")
    );
    formData.append("email", userEmail);
    formData.append("message", userMessage);
    formData.append("recaptcha_response", recaptchaResponse);

    await axios
      .post(`${URL}side/send-contact-us`, formData)
      .then((res) => {
        if (res.data.succeeded) {
          Swal.fire({
            icon: "success",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
          setRecaptchaKey((prevKey) => prevKey + 1);
          setUserEmail("");
          setUserMessage("");
        } else {
          Swal.fire({
            icon: "error",
            title: getTranslatedWord(res.data.title),
            text: getTranslatedWord(res.data.message),
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  /**for responsivity */
  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  return (
    <>
      <div
        className="my-learning-container"
        onClick={() => {
          dispatch(setNotificationDrawer(false));
        }}
      >
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>Contact Us</title>
          <meta
            name="description"
            content={`Get in touch with us and let us know how we can assist you. Our friendly team is available to answer any questions you may have about our products and services. Fill out our online form or give us a call today.`}
          />
        </Helmet>
        {/* <Navbar /> */}
        <div className="teachers-main-container">
          <div className="title-line-container">
            <div
              className="all-grades-1 valign-text-middle poppins-semi-bold-black-25px"
              style={{ width: "100%" }}
            >
              <TextTR text={"lbl_contact_us"} />
            </div>
            <TeacherTitleLines />
          </div>
          <div className="contact-us-container">
            <div className="contact-us-split">
              <div className="contact-us-left-split">
                <h1>
                  <TextTR text={"contact_us_title"} />
                </h1>
                  <p>
                  <TextTR text={"contact_us_subtitle"} />
                  </p>
                <img
                src="./img/contact-us-image.png"
                  alt="contact-schooltube-img"
                />
              </div>

              <div className="contact-us-right-split">
                <div className="contact-us-form">
                  <h3 className="ask-how-we-can-help">
                    <TextTR text={"contact_us_form_title"} />
                  </h3>
                  <Form
                    onSubmit={handleContactUsSubmit}
                    style={{ width: "100%" }}
                  >
                    <Row>
                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        type="email"
                        onChange={(event) => setUserEmail(event.target.value)}
                        value={userEmail}
                        style={{ width: "100%" }}
                        required
                      />
                    </Row>
                    <Row>
                      <TextField
                        id="outlined-multiline-static"
                        label="Your Message"
                        multiline
                        rows={6}
                        onChange={(event) => setUserMessage(event.target.value)}
                        value={userMessage}
                        style={{ width: "100%" }}
                        required
                      />
                    </Row>
                    <Row>
                      <GoogleRecaptcha
                        setRecaptchaResponse={setRecaptchaResponse}
                        id={3}
                        key={recaptchaKey}
                      />
                    </Row>
                    <div className="edit-profile--form-btn">
                      <Button type="submit" className=" bg-blue-600">
                        Send Message
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isMobile ? null : (
          <UnlockPowerOfStudySection
            group148={unlockPowerOfStudySectionProps}
            group147={unlockPowerOfStudySectionProps}
          />
        )}
      </div>
    </>
  );
}

export default ContactUS;

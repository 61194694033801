import React, { useEffect } from "react";
import { useState, useRef } from "react";
import BTNComponent from "../BTNComponent";
import "./SwiperComponent.css";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// import styles bundle
import "swiper/swiper-bundle.min.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { useMediaQuery } from "react-responsive";
import { DotSpinner } from "@uiball/loaders";

function SwiperComponent(props) {
  const { swiperBtnText, swiperText, swiperTitle } = props;

  /**for playing and pausing the video */
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const handleVideoClick = () => {
    if (videoRef.current) {
      setIsPlaying(!isPlaying);
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  };

  /**for the volume of the video in the slide */
  const [muted, setMuted] = useState(true);
  const handleVolumeUpClick = () => {
    setMuted(false);
  };
  const handleVolumeOffClick = () => {
    setMuted(true);
  };

  const [swiperData, setSwiperData] = useState();
  const [loaded, setIsLoaded] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const togglePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  // Function to skip forward by 10 seconds
  const skipForward = () => {
    videoRef.current.currentTime += 10;
  };

  // Function to skip backward by 10 seconds
  const skipBackward = () => {
    videoRef.current.currentTime -= 10;
  };
  //for loading
  useEffect(() => {
    setSwiperData(props.mainHomeSlider);
    setIsLoaded(true);
  }, [props.mainHomeSlider]);

  // useEffect(() => {
  //   console.log("video Loading: ", videoLoading);
  // }, [videoLoading]);

  /**for responsivity */
  const isMobile = useMediaQuery({ query: "(max-width:650px)" });

  return (
    <>
      <div className="main-swiper">
        <Swiper
          className="mySwiper"
          // new
          // autoplay={{
          //   delay: 25000,
          //   disableOnInteraction: false,
          // }}
          pagination={{
            clickable: true,
          }}
          loop={false}
          modules={[Autoplay, Pagination, Navigation]}
        >
          {loaded && swiperData ? (
            swiperData?.sliders?.map((sliderItem, sliderIndex) => {
              if (sliderItem.is_video == 0) {
                return (
                  <SwiperSlide key={sliderIndex}>
                    <div
                      className="swiper-1 "
                      style={{
                        background: `url(${sliderItem.attachment})`,
                        backgroundRepeat: "no-repeat",
                        zIndex: "2",
                        backgroundColor: "black",
                      }}
                    >
                      <div className="text">
                        <h1 className="title valign-text-middle">
                          {sliderItem.title}
                        </h1>
                        <p className="we-are-your-best-fri">
                          {sliderItem.body}
                        </p>
                      </div>
                      {sliderItem.btn_text && (
                        <a href={sliderItem.btn_link}>
                          <Button className="swiper-btn">
                            {sliderItem.btn_text}
                          </Button>
                        </a>
                      )}
                      <div className="swiper-blent"></div>
                    </div>
                  </SwiperSlide>
                );
              } else {
                return (
                  <SwiperSlide
                    key={sliderIndex}
                    //onClick={handleVideoClick}
                  >
                    {!videoLoading ? (
                      <>
                        <div className="swiper-shimmer"></div>
                        <video
                          ref={videoRef}
                         
                          src={`${sliderItem.attachment}`}
                          muted={true}
                          onPlay={() => setVideoLoading(true)}
                          controlsList="nodownload"
                          className="feed-video feeds-blent"
                        ></video>
                      </>
                    ) : (
                      <div
                        className="swiper-1"
                        // style={{ backgroundColor: "transparent !important" }}
                      >

                        <div className="bg-video-container">
                        
                          <video
                            ref={videoRef}
                            src={`${sliderItem.attachment}`}
                          controls
                            controlsList="nodownload"
                            autoPlay
                            loop={true}
                            className="bg-video"
                            
                            muted={muted}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>
                       
                      </div>
                    )}
                  </SwiperSlide>
                );
              }
            })
          ) : (
            <div className="swiper-shimmer">hello there </div>
          )}
        </Swiper>
      </div>
    </>
  );
}

export default SwiperComponent;
